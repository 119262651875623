import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'utilities/i18n';
import { useSelector, useDispatch } from 'react-redux';
import { bindAccount, authBindAccount } from 'domain/use-case';
import { setLoadingState } from 'adapters';
import { checkFormatPhone, GOPAY, normalizePhoneNumber, phoneNumber, SHOPEEPAY, XENDIT_WALLET, LINKAJA_APP, TSELSMARTPAY } from 'utilities';

export default function BindingPageViewModel() {
    const stateGlobal = useSelector((states) => states);
    const dispatch = useDispatch();
    const { state } = useLocation();
    let navigate = useNavigate();
    const { payment_info, customer_info, redirect, channel, partner_credential } = state;
    const [number, setNumber] = useState(normalizePhoneNumber(customer_info.cust_phone) || '');
    const [showError, setShowError] = useState(false);
    const [showModalAlert, setShowModalAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('failed_3');

    const binding_body = {
        payment_provider: payment_info.payment_provider,
    };

    const numberPhone = (e) => {
        setNumber(e);
    };

    // redirect to dana binding url
    const initiateBindingDana = () => {
        if (partner_credential) {
            window.location.replace(
                process.env.REACT_APP_ENVIRONMENT === 'development'
                    ? `https://m.sandbox.dana.id/m/portal/oauth?clientId=${partner_credential.bind_info.client_id}&subMerchantID=${partner_credential.bind_info.merchant_id}&scopes=DEFAULT_BASIC_PROFILE,AGREEMENT_PAY,QUERY_BALANCE,CASHIER,MINI_DANA&requestId=${partner_credential.bind_info.request_id}&state=321654123&terminalType=APP&redirectUrl=https://10.52.109.31/redirect/dana/?channel=${channel}`
                    : `https://m.dana.id/m/portal/oauth?clientId=${partner_credential.bind_info.client_id}&subMerchantID=${partner_credential.bind_info.merchant_id}&scopes=DEFAULT_BASIC_PROFILE,AGREEMENT_PAY,QUERY_BALANCE,CASHIER,MINI_DANA&requestId=${partner_credential.bind_info.request_id}&state=321654123&terminalType=APP&redirectUrl=https://payment.telkomsel.com/redirect/dana/?channel=${channel}`
            );
        } else {
            setShowModalAlert(true);
        }
    };

    // initiate binding based on partner
    const initiateBinding = () => {
        if (payment_info.payment_provider === GOPAY || payment_info.payment_provider === XENDIT_WALLET) {
            if (checkFormatPhone(number)) {
                binding_body.payment_id = phoneNumber(number);
            } else {
                binding_body.payment_id = `62${number}`;
            }

            if (payment_info.payment_provider === XENDIT_WALLET) {
                binding_body.bank_name = payment_info.bank_name;
            }
            // initiateBindingGopayandOvo();
            initiateBindingPartner(false)
        } else if (payment_info.payment_provider === SHOPEEPAY || payment_info.payment_provider === LINKAJA_APP || payment_info.payment_provider === TSELSMARTPAY) {
            // initiateBindingShopee();
            initiateBindingPartner(true)
        } else {
            initiateBindingDana();
        }
    };

    const initiateBindingPartner = async (isAuthBindAccount) => {
        try {
            dispatch(setLoadingState({ loading: true }));
            const response =  isAuthBindAccount? await authBindAccount(binding_body, stateGlobal.upp_data.token)
                : await bindAccount(binding_body, stateGlobal.upp_data.token);

            if (response && response.status_code === '00') {
                redirectBinding(response);
            } else if (response && Object(response.data.payment_response).length > 0) {
                response.data.payment_response?.response_desc?.includes('User Not Found.') && setShowError(true);
            } else {
                if (response.data.status_desc.includes('Already Binded')) {
                    setErrorMessage('failed_binded');
                }
                if (response.data.status_desc.includes('Limit Binding Attempt')) {
                    setErrorMessage('failed_limit');
                }
                dispatch(setLoadingState({ loading: false }));
                setShowModalAlert(true);
            }
        } catch (errors) {
            dispatch(setLoadingState({ loading: false }));
            setShowModalAlert(true);
        }
    };

    // redirect gopay binding url based on device (if mobile, using deeplink url)
    const redirectBinding = (res) => {
        if (
            res.payment_provider === GOPAY ||
            res.payment_provider === XENDIT_WALLET ||
            res.payment_provider === SHOPEEPAY ||
            res.payment_provider === LINKAJA_APP ||
            res.payment_provider === TSELSMARTPAY
        ) {
            if (res.payment_response.binding_url && res.payment_response.binding_url !== null) {
                window.location.replace(res.payment_response.binding_url);
            } else if (res.payment_response.binding_info && res.payment_response.binding_info !== null) {
                window.location.assign(res.payment_response.binding_info);
            } else {
                window.location.replace(stateGlobal.upp_data.failed_url);
            }
        } else {
            window.location.assign(redirect);
        }
    };

    const goToPreviousPath = () => {
        navigate(-1);
    };

    return {
        // error,
        goToPreviousPath,
        customer_info,
        number,
        setNumber,
        showModalAlert,
        setShowModalAlert,
        errorMessage,
        showError,
        initiateBinding,
        payment_info,
        numberPhone,
    };
}
