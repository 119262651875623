import { fetchApi } from 'utilities';

// update Payment to Elisa
export async function updatePayment(body, auth) {
    try {
        const result = await fetchApi('POST', process.env.REACT_APP_API_HOST, auth, '/elisa/payment', body);
        if (result.status === 200) {
            return result.data;
        }
    } catch (error) {
        return error;
    }
}

export async function getInstructionPayment(body, auth) {
    try {
        const result = await fetchApi('POST', process.env.REACT_APP_API_HOST, auth, '/elisa/getPaymentInstruction', body);
        if (result.status === 200) {
            return result.data;
        }
    } catch (error) {
        return error;
    }
}

export async function postPaymentStatusElisa(auth) {
    try {
        const result = await fetchApi('GET', process.env.REACT_APP_API_HOST, auth, '/elisa/check-payment-status');
        if (result.status === 200) {
            return result.data;
        }
    } catch (error) {
        return error;
    }
}

// inquiry payment bca dd
export async function inquiryBCADD(payment_provider, auth) {
    try {
        const result = await fetchApi(
            'POST',
            process.env.REACT_APP_API_HOST,
            auth,
            `/elisa/inquiry-phone`,
            {
                payment_provider: payment_provider
            }
        );
        if (result.status === 200) {
            return result.data;
        }
    } catch (error) {
        return error;
    }
}

