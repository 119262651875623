import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';
import PaymentCategory from './PaymentCategory';
import PaymentPartner from './PaymentPartner';
import {
    CC,
    TELKOMSEL,
    price,
    VA,
    Virtual_Account,
    handleSelect,
} from 'utilities';
import Section from './Section';
import RecommendationSection from './RecommendationSection';
import ExternalPaymentSection from './ExternalPaymentSection';
import { sortingPaymentPartner } from 'utilities/sortingPaymentPartner';

// list partner section of landing page
const PartnerSection = ({
    data,
    selectedValue,
    onDirectOpenBindingPage,
    onChanged,
    balance,
    token,
    body,
    msisdn_type,
    listBank,
    onBind,
    onUnbind,
    checkBinding,
    externalPayment,
    category,
    admin_fee_value,
    platform_fee_value,
    productPrice,
    totalPayment,
    handleNavigate,
}) => {
    const { t } = useTranslation();

    const { recommendation, listSort } = sortingPaymentPartner(data, category);

    // set selected partner
    const controlProps = (item) => ({
        checked: selectedValue === item,
        onChange: onChanged,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
    });

    const msisdnType = () => {
        if (msisdn_type.toLowerCase() === 'postpaid') {
            return t('bill');
        } else {
            return t('balance');
        }
    };

    const ListBankSection = () => {
        if (listBank) {
            return (
                <div>
                    {listBank.length !== 0 ? <PaymentCategory title={t('va')} /> : null}
                    {listBank.map((item, index) => {
                        return (
                            <PaymentPartner
                                key={item.bank_name}
                                items={item}
                                token={token}
                                onCheckBinding={checkBinding}
                                onChanged={controlProps(item.bank_name)}
                            />
                        );
                    })}
                </div>
            );
        }
        return null;
    };

    return (
        <div className="md:mb-0 lg:mb-0">
            {listSort.length !== 0 ? (
                <div>
                    <RecommendationSection
                        onChanged={onChanged}
                        recomendationData={recommendation}
                        selectedValue={selectedValue}
                        token={token}
                        body={body}
                        balance={balance}
                        onDirectOpenBindingPage={onDirectOpenBindingPage}
                        onBind={onBind}
                        msisdnType={msisdnType()}
                        onUnbind={onUnbind}
                        checkBinding={checkBinding}
                    />
                    {category.map((itemType, index, array) => {
                        return (
                            <Section
                                key={index}
                                onChanged={onChanged}
                                data={listSort}
                                selectedValue={selectedValue}
                                onBind={onBind}
                                body={body}
                                balance={balance}
                                msisdnType={msisdnType()}
                                onUnbind={onUnbind}
                                checkBinding={checkBinding}
                                categoryCode={itemType}
                                title={t(itemType)}
                                token={token}
                                last={(array.length - 1 === index) && (externalPayment.length === 0)}
                            />
                        );
                    })}
                </div>
            ) : null}
            <ListBankSection />
            <ExternalPaymentSection
                externalPayment={externalPayment}
                VA={VA}
                CC={CC}
                onChanged={onChanged}
                selectedValue={selectedValue}
            />
            <div className="w-full p-4 hidden lg:flex md:flex justify-end">
                {/* <p className="font-face-tselbold">{t('choose_payment')}</p> */}
                {/* <div>
                    {selectedValue !== '' ? (
                        <div>
                            <PaymentPartnerLogo
                                items={
                                    data.filter((word) =>
                                        word.payment_provider.includes(handleSelect(selectedValue))
                                    )[0]
                                }
                            />
                            <p className="font-face-tselbold">Gopay</p>
                        </div>
                    ) : (
                        <p className="font-face-tselbold">{t('choose_payment')}</p>
                    )}
                    <p className="font-face-poppinsbold text-total">{totalPrice()}</p>
                </div> */}
                <button
                    className="rounded-3xl w-1/2 bg-red-tsel mt-2 h-1/2 p-4 hover:bg-red-500"
                    onClick={() => handleNavigate()}
                >
                    <p className="text-white text-button font-face-poppinsbold">{t('paybutton')}</p>
                </button>
            </div>
        </div>
    );
};

export default PartnerSection;
