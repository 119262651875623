import { useState, useEffect, useCallback } from 'react';
import html2canvas from 'html2canvas';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    copyToClipboard,
    DEFAULT_MINUTE,
    DEFAULT_SECOND,
    DEFAULT_HOUR,
    DEFAULT_HOUR_INDOMARET,
    DEFAULT_MINUTE_INDOMARET,
    DEFAULT_SECOND_INDOMARET,
    INDOMARET,
    setPartnerDisplayName,
} from 'utilities';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';
import { useDispatch } from 'react-redux';
import { setLoadingState } from 'adapters';
import getStaticInstructionPayment from 'domain/use-case/payment/getStaticInstructionPayment';

export default function PaymentCodePageViewModel() {
    const { t } = useTranslation();
    const [token, setToken] = useState();
    const [instructions, setInstructions] = useState();
    const [minutes, setMinutes] = useState();
    const [seconds, setSeconds] = useState();
    const [hours, setHours] = useState();
    const dispatch = useDispatch();
    const [channel, setChannel] = useState('');
    const { state } = useLocation();
    const { code, total, detail, payment_info, app, redirect, failed } = state;
    let { merchant } = useParams();

    const isSecond = () => {
        let hour_in_second = Math.floor(payment_info.expiry_duration / 3600);
        let minute_in_second = Math.floor((payment_info.expiry_duration % 3600) / 60);
        let second_in_second = payment_info.expiry_duration - hour_in_second * 3600 - minute_in_second * 60;
        setHours(hour_in_second);
        setMinutes(minute_in_second);
        setSeconds(second_in_second);
    };

    const isMinute = () => {
        let hour_in_minute = Math.floor(payment_info.expiry_duration / 60);
        let minute_in_minute = Math.floor(payment_info.expiry_duration % 60);
        let second_in_minute = 0;
        setHours(hour_in_minute);
        setMinutes(minute_in_minute);
        setSeconds(second_in_minute);
    };

    const isHour = () => {
        let hour_in_hour = payment_info.expiry_duration;
        let minute_in_hour = 0;
        let second_in_hour = 0;
        setHours(hour_in_hour);
        setMinutes(minute_in_hour);
        setSeconds(second_in_hour);
    };

    const isDay = () => {
        let hour_in_day = payment_info.expiry_duration * 24;
        let minute_in_day = 0;
        let second_in_day = 0;
        setHours(hour_in_day);
        setMinutes(minute_in_day);
        setSeconds(second_in_day);
    };

    const isDefault = () => {
        if (merchant === INDOMARET) {
            setHours(DEFAULT_HOUR_INDOMARET);
            setMinutes(DEFAULT_MINUTE_INDOMARET);
            setSeconds(DEFAULT_SECOND_INDOMARET);
        } else {
            setHours(DEFAULT_HOUR);
            setMinutes(DEFAULT_MINUTE);
            setSeconds(DEFAULT_SECOND);
        }
    };

    // calculate remaining time based on expiry duration and duration unit
    useEffect(() => {
        dispatch(setLoadingState({ loading: false }));
        switch (payment_info.duration_unit) {
            case 'second':
                isSecond();
                break;
            case 'minute':
                isMinute();
                break;
            case 'hour':
                isHour();
                break;
            case 'day':
                isDay();
                break;
            default:
                isDefault();
        }
        // eslint-disable-next-line
    }, []);

    // set partner display name format
    useEffect(() => {
        setChannel(setPartnerDisplayName(payment_info.payment_provider, merchant));
    }, [merchant, payment_info.payment_provider]);

    // redirect to failed page if countdown finished

    // copy payment code and show toast
    const notify = () => {
        toast.info(t('copied_to_clipboard'), {
            position: 'top-left',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        copyToClipboard(code);
    };

    const countHourOnly = useCallback(
        (interval) => {
            if (hours === 0) {
                clearInterval(interval);
            } else {
                setHours(hours - 1);
            }
        },
        [hours]
    );

    const countHourMinutesSeconds = useCallback(
        (interval) => {
            if (minutes === 0) {
                setMinutes(59);
                countHourOnly(interval);
            } else {
                setMinutes(minutes - 1);
                setSeconds(59);
            }
        },
        [countHourOnly, minutes]
    );

    const countDown = useCallback(
        (interval) => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else if (seconds === 0) {
                countHourMinutesSeconds(interval);
            }
        },
        [countHourMinutesSeconds, seconds]
    );

    // perform countdown
    useEffect(() => {
        let myInterval = setInterval(() => {
            countDown(myInterval);
            // redirect to failed page if countdown finished
            if (hours === 0 && minutes === 0 && seconds === 0) {
                window.location.replace(failed);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [countDown, failed, hours, minutes, seconds]);

    const getInstructions = async (token, payment, bank_name = null) => {
        const payload = {
            partner_code: payment,
            partner_agg_code: bank_name,
        };
        try {
            const response = await getStaticInstructionPayment(payload, token);
            if (response && response.status_code === '00') {
                setInstructions(response);
            }
        } catch (e) {
            console.error(e);
        }
    };

    // const handleDownloadImage = async () => {
    //     const button = document.getElementById('copy-button');
    //     button.style.display = 'none';
    //     const element = document.getElementById('payment-code'),
    //     canvas = await html2canvas(element, {
    //         useCORS: true, allowTaint: true, imageTimeout: 0, logging: true
    //       }),
    //     data = canvas.toDataURL('image/png').replace("image/png", "image/png;content-disposition:attachment;filename=payment-code.png"),
    //     link = document.getElementById('download');
    //     link.href = data;
    //     link.click();
    //     button.style.display = 'block';
    //   };

    // // Fungsi konversi Data URL ke Blob
    // const dataURLtoBlob = (dataURL) => {
    //     const arr = dataURL.split(',');
    //     const mime = arr[0].match(/:(.*?);/)[1];
    //     const bstr = atob(arr[1]);
    //     let n = bstr.length;
    //     const u8arr = new Uint8Array(n);
    //     while (n--) {
    //         u8arr[n] = bstr.charCodeAt(n);
    //     }
    //     return new Blob([u8arr], { type: mime });
    // };

    // const handleDownloadImage = async () => {
    //     const element = document.getElementById("payment-code");
    //     const canvas = await html2canvas(element, { useCORS: true });
    
    //     const dataURL = canvas.toDataURL("image/png");
    //     const blob = dataURLtoBlob(dataURL); // Konversi ke Blob
    
    //     saveAs(blob, "payment-code.png"); // Simpan file
    // };

    const handleDownloadImage = async () => {
        const button = document.getElementById('copy-button');
        button.style.display = 'none';

        const element = document.getElementById('payment-code');
        const canvas = await html2canvas(element, {
            useCORS: true,
            allowTaint: true,
            imageTimeout: 0,
            logging: true
        });

        canvas.toBlob(async blob => {
            const blobUrl = URL.createObjectURL(blob);
            if (window.webkit && window.webkit.messageHandlers) { 
                const base64Data = await blobToBase64(blob);
                window.webkit.messageHandlers.downloadHandler.postMessage({
                data: base64Data,
                filename: 'payment-code.png',
                mimeType: 'image/png'
                });
                console.log('ios');
            } else if (window.AndroidInterface) {
                const base64Data = await blobToBase64(blob);
                window.AndroidInterface.downloadFile(
                    base64Data,
                    'payment-code.png',
                    'image/png'
                );
                console.log('android');
            } else {
                // Regular browser fallback
                const link = document.getElementById('download');
                link.download = 'payment-code.png';
                link.href = blobUrl;
                link.click();
                URL.revokeObjectURL(blobUrl);
                console.log('web');
            }
        }, 'image/png');
        button.style.display = 'block';
    };

    function blobToBase64(blob) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result.split(',')[1]);
            reader.readAsDataURL(blob);
        });
    }

    return {
        total,
        detail,
        channel,
        hours,
        minutes,
        seconds,
        notify,
        code,
        payment_info,
        redirect,
        app,
        setToken,
        token,
        getInstructions,
        instructions,
        setInstructions,
        handleDownloadImage,
    };
}
