import { fetchApi, fetchApiGet } from 'utilities';

// get response from validated jwt token
export async function validateToken(auth, body) {
    try {
        const result = await fetchApi('POST', process.env.REACT_APP_API_HOST, auth, '/auth/validate', body);
        if (result.status === 200) {
            return result.data.data;
        }
    } catch (error) {
        return error;
    }
}

// get response from validated jwt token for Payment Page Inbox
export async function validateInboxToken(auth) {
    try {
        const result = await fetchApiGet('GET', process.env.REACT_APP_API_HOST, auth, '/elisa/getpaymentcode');
        if (result.status === 200) {
            return result.data.data;
        }
    } catch (error) {
        return error;
    }
}

// get response from validated jwt token for Static Connecting Page
export async function getStaticConnectingPageRepository(auth, body) {
    try {
        const result = await fetchApi(
            'POST',
            process.env.REACT_APP_API_HOST,
            auth,
            '/auth/getConnectingPageDescription',
            body
        );
        if (result.status === 200) {
            return result.data;
        }
    } catch (error) {
        return error;
    }
}
