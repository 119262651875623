import React from 'react';
import DanaLogo from 'presentations/assets/images/dana.png';
import GopayLogo from 'presentations/assets/images/gopay.png';
import LinkAjaLogo from 'presentations/assets/images/ewallet/link-aja.png';
import ShopeePayLogo from 'presentations/assets/images/ewallet/shopee-pay.png';
import OVOLogo from 'presentations/assets/images/ovo.png';
import QRISLogo from 'presentations/assets/images/logoqris.png';
import AlfamartLogo from 'presentations/assets/images/alfamart.png';
import IndomaretLogo from 'presentations/assets/images/indomaret.png';
import AkulakuLogo from 'presentations/assets/images/akulaku.jpg';
import KredivoLogo from 'presentations/assets/images/kredivo.jpg';
import CreditCardLogo from 'presentations/assets/images/card.png';
import BNILogo from 'presentations/assets/images/bank/bni.png';
import BCALogo from 'presentations/assets/images/bank/bca_new.png';
import PermataLogo from 'presentations/assets/images/bank/permata.png';

import BRILogo from 'presentations/assets/images/bri.png';
import MandiriLogo from 'presentations/assets/images/bank/mandiri.png';
import CIMBLogo from 'presentations/assets/images/bank/cimb.png';
import MuamalatLogo from 'presentations/assets/images/bank/muamalat.png';
import BjbLogo from 'presentations/assets/images/bank/bjb-va.png';
import BtnLogo from 'presentations/assets/images/bank/va-btn.png';
import BsiLogo from 'presentations/assets/images/bank/va-bsi.png';
import BncLogo from 'presentations/assets/images/bank/va-bnc.png';
import NagariLogo from 'presentations/assets/images/bank/va-nagari.png';
import MegaLogo from 'presentations/assets/images/bank/va-mega.png';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import {
    AKULAKU,
    ALFAMART,
    BCA_DD,
    BJB,
    BNC,
    BNI_DD,
    BRI_DD,
    BSI,
    BTN,
    CREDIT_CARD,
    DANA,
    FINNET_CC,
    GOPAY,
    INDOMARET,
    KREDIVO,
    LINKAJA,
    LINKAJA_APP,
    LINKAJA_WCO,
    MCP_CC,
    MEGA,
    NAGARI,
    OVO,
    QRIS,
    SHOPEEPAY,
    XENDIT_WALLET,
} from 'utilities';

export const MerchantLogo = (props) => {
    const { channel } = props;
    if (channel?.includes('http')) {
        return <img src={channel} alt="Logo" className="mx-auto w-20 lg:w-1/6 xl:w-1/6" />;
    } else {
        return <img src={`${process.env.REACT_APP_IMAGE_HOST}/public/icon/${channel?.toLowerCase()}.png`} alt="Logo" className="mx-auto w-20 lg:w-1/6 xl:w-1/6" />;
    }
};

export const Title = (props) => {
    const { partner, text } = props;
    switch (partner) {
        case OVO:
            return <p className="font-face-tselbold mx-auto text-center text-topbar">OVO</p>;
        case LINKAJA_APP:
            return <p className="font-face-tselbold mx-auto text-center text-topbar">LinkAja</p>;
        case LINKAJA_WCO:
            return <p className="font-face-tselbold mx-auto text-center text-topbar">LinkAja</p>;
        case SHOPEEPAY:
            return <p className="font-face-tselbold mx-auto text-center text-topbar">ShopeePay</p>;
        case GOPAY:
            return <p className="font-face-tselbold mx-auto text-center text-topbar">Gopay</p>;
        case DANA:
            return <p className="font-face-tselbold mx-auto text-center text-topbar">DANA</p>;
        default:
            return <p className="font-face-tselbold mx-auto text-center text-topbar">{text}</p>;
    }
};

export const HeaderLogo = (props) => {
    let { partnerlogo } = props;
    if (partnerlogo==LINKAJA_APP || partnerlogo==LINKAJA_WCO){
        partnerlogo = LINKAJA   
    }
    if (partnerlogo?.includes('http')) {
        return <img src={partnerlogo} alt="Logo" className="max-h-10 lg:max-h-7 w-auto" />;
    } else {
        return <img src={`${process.env.REACT_APP_IMAGE_HOST}/public/icon/${partnerlogo}.png`} alt="Logo" className="max-h-10 lg:max-h-7 w-auto" />;
    }
};

export const KeyboardIcon = ({ checked }) => {
    return checked 
        ? <KeyboardArrowUpIcon fontSize="large" data-testid="KeyboardArrowUpIcon" />
        : <KeyboardArrowDownIcon fontSize="large" data-testid="KeyboardArrowDownIcon" />;
};