import 'presentations/assets/css/app.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    BindingPage,
    ConnectingPage,
    CreditCardPage,
    ErrorPage,
    FailedBindingPage,
    InboxPaymentCodePage,
    LandingPage,
    LoadingPage,
    PaymentCodePage,
    QRISPage,
    RedirectPage,
    SuccessBindingPage,
    BindingDDPage,
    DirectPage,
} from 'presentations/pages';
import { useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';
import DirectDebitPage from 'presentations/pages/directdebit-page/DirectDebitPage';

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTMID,
    dataLayer: {
        userId: '001',
        userProject: 'UPP',
    },
};
TagManager.initialize(tagManagerArgs);

function App() {
    const stateGlobal = useSelector((state) => state);
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <script
                        id="midtrans-script"
                        src="https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js"
                        data-environment={
                            process.env.REACT_APP_ENVIRONMENT === 'development' ? 'sandbox' : 'production'
                        }
                        data-client-key={stateGlobal.upp_data.client_key}
                        type="text/javascript"
                    ></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/picomodal/3.0.0/picoModal.js"></script>
                </Helmet>
            </HelmetProvider>
            {/* {stateGlobal.upp_data.loading && <LoadingPage className="z-100" />} */}
            <div className={`App`}>
                <Router basename="/">
                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/paymentcode/:merchant" element={<PaymentCodePage />} />
                        <Route path="/payment/:partner" element={<ConnectingPage />} />
                        <Route path="/directpayment/:partner" element={<DirectPage />} />
                        <Route path="/qris" element={<QRISPage />} />
                        <Route path="/paymentCode" element={<InboxPaymentCodePage />} />
                        <Route path="/creditcardform" element={<CreditCardPage />} />
                        <Route path="/directdebit" element={<DirectDebitPage />} />
                        <Route path="/binding" element={<BindingPage />} />
                        <Route path="/binding/:partner" element={<BindingDDPage />} />
                        <Route path="/success_binding" element={<SuccessBindingPage />} />
                        <Route path="/redirect/:partner/" element={<RedirectPage />} />
                        <Route path="/failed_binding" element={<FailedBindingPage />} />
                        <Route path="*" element={<ErrorPage />} />
                    </Routes>
                </Router>
            </div>
        </>
    );
}

export default App;
