import React, { useState } from 'react';
import { PaymentPartnerLogo } from './PaymentPartnerLogo';
import Balance from '../../../assets/images/balance.svg';
import { BALANCE } from 'utilities';
// payment category component
const PaymentPartnerCollapse = (props) => {
    const { data, setExpand, expand } = props;
    const [loading, setLoading] = useState(true)

    return (
        <div className="flex w-full bg-white pl-2 pr-4 py-2 rounded-b-2xl flex-wrap min-h-10" onClick={() => setExpand(!expand)}>
            {data?.map((items, index) => {
                if (index > 5) {
                    return null;
                }
                if (index === 5 && !loading) {
                    return (
                        <div key={index} className="flex items-center justify-center align-center m-2 min-w-10 min-h-10 outline-gray text-xs text-gray-500 px-2 rounded-sm">
                            +{data.length - 5}
                        </div>
                    );
                }
                return (
                    <>
                        {/* {isTelkomsel  && (
                            <div className="flex items-center justify-center p-1.5 align-center">
                                <img src={Balance} alt="Logo" className="min-w-10" />
                            </div>
                        )}

                        {(!isTelkomsel && (data.length > 0)) ? <div key={index} className="flex items-center justify-center align-center m-2">
                            <PaymentPartnerLogo items={items} setLoading={() => setLoading(false)} />
                        </div> : []} */}

                        {items?.partner_provider === BALANCE  && (
                            <div className="flex items-center justify-center p-1.5 align-center">
                                <img src={Balance} alt="Logo" className="min-w-10" />
                            </div>
                        )}

                        {(items?.partner_provider !== BALANCE && (data.length > 0)) ? <div key={index} className="flex items-center justify-center align-center m-2">
                            <PaymentPartnerLogo items={items} setLoading={() => setLoading(false)} />
                        </div> : []}

                    </>
                );
            })
            }
        </div>
    );
};

export default PaymentPartnerCollapse;
