import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AGGREGATOR_PARTNER, checkDevice, getToken } from 'utilities';
import { useDispatch, useSelector } from 'react-redux';

import {
    HelpSection,
    PaymentTotal,
    ProductDetail,
    TopBar,
    LinkAjaWco,
    MandiriDDWco,
    ModalSelectBind,
} from 'presentations/shared';
import { FooterWeb, PartnerSection } from './Components';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';
import { storeToken, storeDevice } from 'adapters';
import useViewModel from './LandingPageViewModel';
import ErrorPage from '../error-page';
import MaintenancePage from '../maintenance-page';
import ToppingDetail from 'presentations/shared/ToppingDetail';
import RequestOtpPage from '../otp-page/index';
import {
    BalanceAlert,
    BindingModal,
    GeneralError,
    InquiryModal,
    NoPartnerSelected,
    OTPModal,
    RequestOtp,
    PaymentModal,
    PromoPage,
    ShowHeader,
    UnbindingModal,
    UnbindSuccess,
} from './LandingPage';
import OtpPage from '../directdebit-page/OtpPage';
import SkeletonPartner from 'presentations/shared/components/SkeletonPartner';
import SecurePayment from 'presentations/shared/SecurePayment';

// Main Landing page that show list payment partner
function LandingPage() {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [token, setToken] = useState('');
    const stateGlobal = useSelector((state) => state);
    const {
        showModalOffer,
        showModalAlert,
        validateOrder,
        goToPreviousPath,
        submitOrdertoElisa,
        data,
        externalPayment,
        selectedValue,
        selectedValueParse,
        setSelectedValue,
        handleChange,
        bodytoElisa,
        balance,
        selectPromo,
        showPromo,
        setShowPromo,
        getSelectedPromo,
        setSelectedPromo,
        showModalPayment,
        setModalPayment,
        setSelectedPaymentPromo,
        onBind,
        setShowModalUnbind,
        checkStatusBinding,
        showModalAlertError,
        showBalanceAlert,
        showModalUnbind,
        showModalOTPUnbinding,
        errorModalMessage,
        setShowModalOTPUnbinding,
        setShowBalanceAlert,
        setShowModalAlertError,
        setShowModalAlert,
        setShowModalOffer,
        onUnbind,
        maintenance,
        error,
        isLinkAjaWco,
        linkajaAuth,
        linkajaUrl,
        isMandiriDDWco,
        mandiriDDRes,
        deviceType,
        setDeviceType,
        setShowModalOTP,
        showModalOTP,
        setOTPCode,
        isExpand,
        setIsExpand,
        handleOTPUnbinding,
        otpWarning,
        paymentId,
        showModalDouPay,
        modalWording,
        handleDoubleMethode,
        setShowModalDouPay,
        setShowUnBindSuccess,
        showUnBindSuccess,
        showModalInquiry,
        setShowModalInquiry,
        dataInquiry,
        selectInquiry,
        setSelectInquiry,
        onSuccessUnbind,
        showOTP,
        setShowOTP,
        setOtp,
        submitReqOtp,
        total,
        itemFee,
        platformFee,
        productPrice,
    } = useViewModel();

    useEffect(() => {
        // get device type
        setDeviceType(checkDevice);
        // get Token
        setToken(getToken);
        if (token) {
            // store to redux state
            dispatch(storeDevice({ device: deviceType }));
            dispatch(storeToken({ token: token }));
            // call API validate jwt token
            validateOrder(token);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, deviceType]);

    useEffect(() => {
        setSelectedPromo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateGlobal.upp_data.promo]);

    // show if no balance, list_bank, list_payment_partner, additional_external_partner on response
    if (maintenance) {
        return <MaintenancePage urlBack={bodytoElisa.redirect_url_back} deviceType={deviceType} />;
    }

    // show error page or redirect to failed url
    if (error) {
        if (stateGlobal.upp_data.failed_url !== '') {
            window.location.replace(stateGlobal.upp_data.failed_url);
        } else {
            return <ErrorPage />;
        }
    }

    const isShowModal = () => {
        if (showModalOffer || showModalAlert) {
            return 'overflow-hidden';
        }
    };

    // open linkaja webcheckout
    if (isLinkAjaWco) {
        return <LinkAjaWco auth={linkajaAuth} action={linkajaUrl} />;
    }

    // open mandiri web checkout
    if (isMandiriDDWco) {
        return (
            <MandiriDDWco
                binding_url={mandiriDDRes.binding_url}
                binding_code={mandiriDDRes.binding_code}
                request_id={mandiriDDRes.request_id}
                binding_id={mandiriDDRes.binding_id}
                token_requestor_id={mandiriDDRes.token_requestor_id}
                merchant_id={mandiriDDRes.merchant_id}
                terminal_id={mandiriDDRes.terminal_id}
                public_key={mandiriDDRes.public_key}
                signature={mandiriDDRes.signature}
                lang={bodytoElisa.lang && bodytoElisa.lang === 'id' ? 'ID' : 'EN'}
            />
        );
    }

    return (
        <>
            <div
                className={`flex-1 h-screen md:min-h-screen z-0 lg:min-h-screen lg:h-full md:h-full bg-gray-200 w-full ${isShowModal()} ${
                    showPromo && 'hidden'
                }`}
            >
                <div className="h-screen w-full lg:h-full md:h-full lg:mb-0 md:mb-0 mb-32 pt-17 lg:pt-18 lg:px-14 md:px-14 bg-gray-200">
                    <ShowHeader stateGlobal={stateGlobal} />
                    <div>
                        <TopBar text={t('title')} onClick={goToPreviousPath} device={deviceType} />
                        <div className="lg:w-2/5 md:w-1/2 lg:float-right md:float-right md:px-2 lg:px-4">
                            {(
                                <div className="hidden lg:block md:block">
                                    <PaymentTotal
                                        productPrice={stateGlobal?.upp_data.partner_price}
                                        handleNavigate={submitOrdertoElisa}
                                        handleVoucher={() => setShowPromo(true)}
                                        selectedPromo={getSelectedPromo()}
                                        activePromo={bodytoElisa?.promo_active}
                                        textButton={t('paybutton')}
                                        textPromoButton={t('promobutton')}
                                        word={t('total')}
                                        isExpand={true}
                                        setExpand={() => setIsExpand(!isExpand)}
                                        platform_fee_wording={t('platform_fee')}
                                        admin_fee_wording={t('admin_fee_total')}
                                        promo_wording={{ cashback: t('cashback'), discount: t('discount') }}
                                        payment_details={t('payment_details')}
                                        order_details={t('order_details')}
                                        product={stateGlobal.upp_data.item_detail.item_name}
                                        item_detail={stateGlobal?.upp_data.item_detail}
                                        admin_fee_value={stateGlobal?.upp_data.item_fee}
                                        platform_fee_value={stateGlobal?.upp_data.platform_fee}
                                        totalPayment={stateGlobal?.upp_data.total_price}
                                        loading={stateGlobal.upp_data.loading}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="lg:w-3/5 md:w-1/2 md:overscroll-contain lg:overscroll-none lg:px-2 bg-gray-200">
                            {stateGlobal.upp_data.loading ? <SkeletonPartner />
                            : <PartnerSection
                                data={data || []}
                                deviceType={deviceType}
                                externalPayment={externalPayment || []}
                                selectedValue={selectedValue || ''}
                                onChanged={handleChange}
                                handleNavigate={submitOrdertoElisa}
                                body={bodytoElisa || {}}
                                token={token}
                                balance={balance || ''}
                                msisdn_type={bodytoElisa.msisdn_type ? bodytoElisa.msisdn_type : ''}
                                onDirectOpenBindingPage={setSelectedValue}
                                listBank={bodytoElisa.list_bank ? bodytoElisa.list_bank : []}
                                category={bodytoElisa.categories ? bodytoElisa.categories : []}
                                onBind={onBind}
                                onUnbind={() => setShowModalUnbind(true)}
                                checkBinding={checkStatusBinding}
                                total={stateGlobal.upp_data.total_price}
                                admin_fee_details={stateGlobal?.upp_data.item_fee}
                                productPrice={stateGlobal?.upp_data.partner_price}
                                product={stateGlobal.upp_data.item_detail.item_name}
                                admin_fee_value={stateGlobal?.upp_data.item_fee}
                                platform_fee_value={stateGlobal?.upp_data.platform_fee}
                                totalPayment={stateGlobal?.upp_data.total_price}
                                loading={stateGlobal.upp_data.loading}
                            />}
                            {/*!stateGlobal.upp_data.loading && <HelpSection text={t('tnc_telkomsel_wallet')}
                                cta={{ url: 'https://www.telkomsel.com/?embed=on&wec-version=20230703104900', text: t('cta_tnc_telkomsel_wallet')}} /> */}
                        </div>
                        <div className={`lg:h-5 md:h-0 ${isExpand ? 'h-96' : 'h-60'} bg-gray-200`} />
                    </div>
                    {(
                        <div className="lg:hidden md:hidden">
                            <PaymentTotal
                                productPrice={stateGlobal?.upp_data.partner_price}
                                handleNavigate={submitOrdertoElisa}
                                handleVoucher={() => setShowPromo(true)}
                                selectedPromo={getSelectedPromo()}
                                activePromo={bodytoElisa?.promo_active}
                                textButton={t('paybutton')}
                                textPromoButton={t('promobutton')}
                                word={t('total')}
                                isExpand={isExpand}
                                setExpand={() => setIsExpand(!isExpand)}
                                platform_fee_wording={t('platform_fee')}
                                admin_fee_wording={t('admin_fee_total')}
                                promo_wording={{ cashback: t('cashback'), discount: t('discount') }}
                                payment_details={t('payment_details')}
                                product={stateGlobal.upp_data.item_detail.item_name}
                                admin_fee_value={stateGlobal?.upp_data.item_fee}
                                platform_fee_value={stateGlobal?.upp_data.platform_fee}
                                totalPayement={stateGlobal?.upp_data.total_price}
                                loading={stateGlobal.upp_data.loading}
                            />
                        </div>
                    )}
                    <div className="flex place-content-center w-full md:block">
                        <SecurePayment/>
                        <FooterWeb text={`© ${(new Date().getFullYear())} ${t('copyright')}`} />
                    </div>
                </div>
            </div>
            <PromoPage
                showPromo={showPromo}
                selectPromo={selectPromo}
                setShowPromo={setShowPromo}
                bodytoElisa={bodytoElisa}
                setShowModalOffer={setShowModalOffer}
                handleChange={handleChange}
            />
            <PaymentModal
                showModalPayment={showModalPayment}
                showPromo={showPromo}
                setModalPayment={setModalPayment}
                setSelectedPaymentPromo={setSelectedPaymentPromo}
            />
            {/* show alert if no partner selected */}
            <NoPartnerSelected showModalAlert={showModalAlert} setShowModalAlert={setShowModalAlert} t={t} />
            <UnbindSuccess showUnBindSuccess={showUnBindSuccess} setShowUnBindSuccess={setShowUnBindSuccess} t={t} />
            {/* show general error */}
            <GeneralError
                showModalAlertError={showModalAlertError}
                setShowModalAlertError={setShowModalAlertError}
                errorModalMessage={errorModalMessage}
                t={t}
            />
            {/* show balance not enough alert */}
            <BalanceAlert
                showBalanceAlert={showBalanceAlert}
                setShowBalanceAlert={setShowBalanceAlert}
                selectedValue={selectedValueParse}
                t={t}
            />
            {/* show modal binding or continue payment offer */}
            <BindingModal
                showModalOffer={showModalOffer}
                selectedValue={selectedValueParse}
                setShowModalOffer={setShowModalOffer}
                onBind={onBind}
                bodytoElisa={bodytoElisa}
                externalPayment={externalPayment}
                token={token}
                stateGlobal={stateGlobal}
                navigate={navigate}
                total={stateGlobal?.upp_data.item_fee}
            />
            {/* show modal unbinding confirmation */}
            <UnbindingModal
                showModalUnbind={showModalUnbind}
                selectedValue={selectedValueParse}
                onSuccessUnbind={onSuccessUnbind}
                setShowModalUnbind={setShowModalUnbind}
                onUnbind={onUnbind}
            />
            {/* show modal inquiry confirmation */}
            {showModalInquiry && (
                <InquiryModal
                    showModalInquiry={showModalInquiry}
                    setSelectInquiry={setSelectInquiry}
                    selectInquiry={selectInquiry}
                    dataInquiry={dataInquiry}
                    reqOtp={submitOrdertoElisa}
                    setShowModalInquiry={setShowModalInquiry}
                />
            )}
            {/* show modal OTP(for mandiriDD payment) */}
            <OTPModal
                showModalOTP={showModalOTP}
                setShowModalOTP={setShowModalOTP}
                setOTPCode={setOTPCode}
                submitOrdertoElisa={submitOrdertoElisa}
            />
            <RequestOtp showOTP={showOTP} setShowOTP={setShowOTP} setOtp={setOtp} submitReqOtp={submitReqOtp} />
            {showModalOTPUnbinding && (
                <OtpPage
                    validateOrder={validateOrder}
                    paymentId={paymentId}
                    text_button="Verifikasi"
                    onClick={() => setShowModalOTPUnbinding(false)}
                    handleOtp={handleOTPUnbinding}
                    otpWarning={otpWarning}
                    type="unbinding"
                />
            )}
            {showModalDouPay && (
                <ModalSelectBind
                    title_text={t(modalWording.modalTitle)}
                    text={t(modalWording.modalText)}
                    text_button_1={t(modalWording.modalButton1)}
                    text_button_2={t(modalWording.modalButton2)}
                    onClick_1={async () => {
                        await setShowModalDouPay(false);
                        handleDoubleMethode('dd');
                    }}
                    onClick_2={async () => {
                        await setShowModalDouPay(false);
                        handleDoubleMethode('');
                    }}
                    img={modalWording.modalImg}
                    onClose={setShowModalDouPay}
                />
            )}
        </>
    );
}

export default LandingPage;
