import React from 'react';
import DOMPurify from 'dompurify';
import { BCA_DD, BNI_DD, BRI_DD, MANDIRI_DD, price, TSELSMARTPAY } from 'utilities';
import { BoxInfo } from 'presentations/shared';
import IconDiscountGreen from 'presentations/assets/images/icons/icon_discount_green.svg';
import ChevronDown from '../../../assets/images/down_chevron.svg';
import { getFirstLine } from 'utilities/stringSplit';
import { useTranslation } from 'react-i18next';

export const Description = (props) => {
    const { items, isDisable, onClick } = props;
    const sanitizer = DOMPurify.sanitize;

    // Fungsi untuk mengambil baris pertama (sesuai kebutuhan Anda)
    const firstline = getFirstLine(items.description);

    // Fungsi untuk memotong teks menjadi maksimal 22 karakter
    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.slice(0, maxLength) + '...';
    };

    if (items.description && items.description !== '' && items.description !== 'null') {
        // Sanitasi terlebih dahulu
        const sanitizedText = sanitizer(firstline ?? '');
        // Lalu potong teks jika melebihi 22 karakter
        const truncatedText = truncateText(sanitizedText, 22);

        return (
            <div
                className={` Description flex flex-1 justify-between items-center text-desc text-gray-desc px-2 mx-2 lg:ml-5 mt-1 font-face-poppinsregular ${isDisable()}`}
                onClick={onClick}
            >
                <div className="flex flex-1 w-full items-center">
                    <img src={IconDiscountGreen} alt="icon" className="w-4 h-4 mr-2" />
                    {/* Tampilkan teks yang sudah dipotong */}
                    <div
                        className="flex-1 textpromo"
                        dangerouslySetInnerHTML={{ __html: sanitizedText }}
                    />
                </div>

                <img className="ml-1 w-3 opacity-40" src={ChevronDown} alt="Description" />
            </div>
        );
    }
    return null;
};

// export const Description = (props) => {
//     const { items, isDisable, onClick } = props;
//     const sanitizer = DOMPurify.sanitize;
//     const firstline = getFirstLine(items.description);
//     if (items.description && items.description !== '' && items.description !== 'null') {
//         return (
//             <div
//                 className={`Description flex items-center w-max text-desc text-gray-desc px-2 font-face-poppinsregular  ${isDisable()}`}
//                 onClick={onClick}
//             >
//                 <img src={IconDiscountGreen} alt="icon" className="w-4 h-4 mr-2" />
//                 <div className="Description" dangerouslySetInnerHTML={{ __html: sanitizer(firstline) }} />
//                 <img className="ml-1 w-3 opacity-40" src={ChevronDown} alt="Description" />
//             </div>
//         );
//     }
//     return null;
// };

export const PartnerDisplayName = (props) => {
    const { items, isDisable } = props;

    if (items.bind_info && items.bind_info.status === 'binding') {
        return <BindingPartner items={items} isDisable={isDisable} />;
    } else {
        return (
            // show normal payment partner
            <p className={`text-partner font-face-poppinsbold ml-3 lg:ml-5 text-blue-dark_blue ${isDisable()}`}>
                {items.display_name}
                {items.bank_display_name}
            </p>
        );
    }
};

// export const BindingPartner = (props) => {
//     const { items, isDisable } = props;
//     const { t } = useTranslation();
//     if (items.partner_provider === MANDIRI_DD) {
//         return (
//             // show masked number for already binding mandiri account
//             <div>
//                 <p className={`text-partner font-face-poppinsbold ml-3 lg:ml-5 text-blue-dark_blue ${isDisable()}`}>
//                     {items.display_name}
//                 </p>
//                 <p className={`text-desc text-gray-desc ml-3 lg:ml-5 font-face-poppinsregular mt-1 ${isDisable()}`}>
//                     {`**** **** **** ${items.bind_info.token_info.maskedCardNumber}`}
//                 </p>
//             </div>
//         );
//     }
//     if (items.partner_provider === BRI_DD || items.partner_provider === BNI_DD || items.partner_provider === BCA_DD) {
//         return (
//             // show masked number for already binding mandiri account
//             <div>
//                 <p className={`text-partner font-face-poppinsbold ml-3 lg:ml-5 text-blue-dark_blue ${isDisable()}`}>
//                     {items.display_name}
//                 </p>
//                 <p className={`text-desc text-gray-desc ml-3 lg:ml-5 font-face-poppinsregular mt-1 ${isDisable()}`}>
//                     {`${items.bind_info.token_info.maskedCardNumber}`}
//                 </p>
//             </div>
//         );
//     } else {
//         return (
//             // show balance for already binding gopay or dana account
//             <div className="pl-3 lg:pl-5 flex flex-col">
//                 <p className={`text-partner font-face-poppinsbold text-blue-dark_blue ${isDisable()}`}>
//                     {items.display_name}
//                 </p>
//                 <p className={`text-partner font-face-poppinsregular text-blue-dark_blue ${isDisable()}`}>
//                     {items.partner_provider === TSELSMARTPAY ? t('remaining') : 'Saldo'} Rp{' '}
//                     {price(
//                         items?.bind_info?.token_info?.balance !== undefined
//                             ? items?.bind_info?.token_info?.balance
//                             : items?.bind_info?.token_info?.wallet_balance !== undefined
//                             ? items?.bind_info?.token_info?.wallet_balance
//                             : items?.bind_info?.token_info?.remaining_user_limit || 0
//                     )}
//                 </p>
//             </div>
//         );
//     }
// };

export const BindingPartner = ({ items, isDisable }) => {
    const { t } = useTranslation();
    const disabledClass = isDisable();

    // Fungsi untuk merender tampilan masked card
    const renderMaskedCard = (prepend = '') => {
        const { display_name, bind_info } = items;
        const maskedCard = bind_info?.token_info?.maskedCardNumber || '';

        return (
            <div>
                <p className={`text-partner font-face-poppinsbold ml-3 lg:ml-5 text-blue-dark_blue ${disabledClass}`}>
                    {display_name}
                </p>
                <p className={`text-desc text-gray-desc ml-3 lg:ml-5 font-face-poppinsregular mt-1 ${disabledClass}`}>
                    {`${prepend}${maskedCard}`}
                </p>
            </div>
        );
    };

    // Kondisi untuk provider Mandiri
    if (items.partner_provider === MANDIRI_DD) {
        return renderMaskedCard('**** **** **** ');
    }

    // Kondisi untuk provider BRI, BNI, dan BCA
    if ([BRI_DD, BNI_DD, BCA_DD].includes(items.partner_provider)) {
        return renderMaskedCard();
    }

    // Default: tampilan untuk provider lain (contoh: GoPay, Dana, dll)
    const { display_name, bind_info } = items;
    const tokenInfo = bind_info?.token_info || {};
    // Prioritaskan balance, jika tidak tersedia fallback ke wallet_balance atau remaining_user_limit
    const amount =
        tokenInfo.balance ?? tokenInfo.wallet_balance ?? tokenInfo.credit_limit_details?.[0].remaining_user_limit ?? 0;
    const label = items.partner_provider === TSELSMARTPAY ? t('remaining') : 'Saldo';

    return (
        <div className="pl-3 lg:pl-5 flex flex-col">
            <p className={`text-partner font-face-poppinsbold text-blue-dark_blue ${disabledClass}`}>{display_name}</p>
            <p className={`text-partner font-face-poppinsregular text-blue-dark_blue ${disabledClass}`}>
                {`${label} Rp ${price(amount)}`}
            </p>
        </div>
    );
};

export const BalanceDisplayName = (props) => {
    const { isDisable, balance, balance_nominal } = props;
    return (
        <div className="flex flex-col ml-3 lg:ml-5">
            <span
                className={`text-partner font-face-poppinsbold text-blue-dark_blue ${
                    isDisable() // flag === 2, will make partner item disabled
                }`}
            >
                {balance}
            </span>
            <span className={`text-partner font-face-poppinsregular text-blue-dark_blue ${isDisable()}`}>
                Saldo Rp {balance_nominal}
            </span>
        </div>
    );
};

export const DisabledMessage = (items) => {
    if (isDisabledMessageExist(items)) {
        return (
            <div className="px-8 lg:px-10 mb-3">
                <BoxInfo text={items.description_disabled} />
            </div>
        );
    }
    return null;
};

export const isDisabledMessageExist = (props) => {
    const { items } = props;
    return items.flag && items.flag === 2 && items.description_disabled !== '' && items.is_show_description_disabled;
};

export const UnbindButton = (props) => {
    const { items, isChecked, onUnbind, t } = props;
    return (
        <>
            {items.bind_info && items.bind_info.status === 'binding' && items.cta && items.cta.is_cta && isChecked ? (
                <button className="w-full py-2" onClick={onUnbind}>
                    <p className="font-face-poppinsbold text-xs text-blue-link text-center">
                        {items.cta.description_cta && items.cta.description_cta !== 'null'
                            ? items.cta.description_cta
                            : t('unbind_account')}
                    </p>
                </button>
            ) : null}
        </>
    );
};
