import { PhoneNumberForm, PartnerHeader, TopBar, ModalAlert } from 'presentations/shared';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';
import { useSelector } from 'react-redux';
import ErrorPage from '../error-page';
import useViewModel from './BindingPageViewModel';
import { DANA, GOPAY, XENDIT_WALLET } from 'utilities';
import { AccountNotFoundNotification } from './BindingPage';

// static binding page with TnC for gopay and dana
export default function BindingPage() {
    const { t } = useTranslation();
    const {
        error,
        goToPreviousPath,
        customer_info,
        number,
        showModalAlert,
        setShowModalAlert,
        showError,
        initiateBinding,
        payment_info,
        numberPhone,
        errorMessage,
    } = useViewModel();
    const stateGlobal = useSelector((state) => state);

    // show error page or redirect to failed url
    if (error) {
        if (stateGlobal.upp_data.failed_url !== '') {
            window.location.replace(stateGlobal.upp_data.failed_url);
        } else {
            return <ErrorPage />;
        }
    }

    const handleString = () => {
        let provider = '';
        payment_info.payment_provider === XENDIT_WALLET ? provider = 'ovo' : provider = payment_info.payment_provider;
        return ' ' + provider.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '').toUpperCase();
    };

    return (
        <>
            <div className="flex flex-col flex-grow h-screen bg-gray-200 lg:px-40">
                <TopBar partner={payment_info.payment_provider} onClick={goToPreviousPath} />
                <div className="h-screen flex flex-col w-full justify-between">
                    <div className="bg-gray-200">
                        <div className="px-8 lg:px-20 py-4 lg:pb-6 rounded-2xl border-2 border-white m-4 bg-white bg-opacity-50 ">
                            <PartnerHeader
                                channel={stateGlobal.upp_data.channel}
                                partnerlogo={payment_info.payment_provider}
                                isTransparant={true}
                            />
                            <p className="mt-7 font-face-tselbold text-base">
                                {t('connectaccount')} {handleString()}
                            </p>
                            <p className="mt-3 font-face-poppinsregular text-sm">
                                {t('bindingwording')} {handleString()}
                            </p>

                            {/* <p className="mt-7 font-face-tselbold text-base">
                                {payment_info.payment_provider === GOPAY
                                    ? t('connectgopay')
                                    : payment_info.payment_provider === DANA
                                    ? t('connectdana')
                                    : t('connectaccount')}
                            </p> */}
                            {/* <p className="mt-3 font-face-poppinsregular text-sm">
                                {payment_info.payment_provider === GOPAY
                                    ? t('gopaybindingwording')
                                    : payment_info.payment_provider === DANA
                                    ? t('danabindingwording')
                                    : t('bindingwording')}
                            </p> */}
                        </div>
                        {payment_info.payment_provider === GOPAY || payment_info.payment_provider === XENDIT_WALLET ? (
                            <div className="px-8 lg:px-20 py-4 rounded-2xl border-2 border-white m-4 bg-white bg-opacity-50 mt-2 lg:mt-4 mb-4">
                                <PhoneNumberForm
                                    error={showError}
                                    customer_info={customer_info}
                                    partner={payment_info.payment_provider}
                                    value={number}
                                    onInput={(e) => numberPhone(e.target.value)}
                                    input={t('gopayinput') + handleString()}
                                    placeholder={t('gopayphoneplaceholder') + handleString()}
                                    account={t('gopayaccount') + handleString()}
                                    disabled={payment_info.payment_provider === GOPAY}
                                />
                                <AccountNotFoundNotification showError={showError} t={t} />
                            </div>
                        ) : null}
                    </div>
                    <div className="bg-white h-22 w-full py-5 px-4 lg:px-20">
                        <button
                            type="submit"
                            className="rounded-3xl w-full mx-auto flex bg-red-tsel py-4 lg:py-5 px-5 hover:bg-red-500 disabled:opacity-50 disabled:bg-red-tsel"
                            onClick={initiateBinding}
                            disabled={showError}
                        >
                            <p className="text-white text-button font-face-poppinsbold mx-auto">{t('verify')}</p>
                        </button>
                    </div>
                </div>
            </div>
            {showModalAlert && (
                <ModalAlert text={t(errorMessage)} text_button="OK" onClick={() => setShowModalAlert(false)} />
            )}
        </>
    );
}
