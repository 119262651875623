import React from 'react';
import DOMPurify from 'dompurify';

export default function HelpSection({ text, cta: { url, text: ctaText } }) {
    const sanitizer = DOMPurify.sanitize;
    if (text !== '') {
        return <>
            <div className="Help" dangerouslySetInnerHTML={{ __html: sanitizer(text) }} />
            {url ? <a className='Help-url' href={url} rel="noreferrer">{ctaText || 'Click'}</a> 
                : <div className='pt-2 bg-gray-300'/>}
        </>
    } else {
        return <div className="bg-gray-200 h-8" data-testid="empty-div" />;
    }
}
