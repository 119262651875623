import React from 'react';
import { PaymentCodeInstructionSection, ScanQRISInstruction } from 'presentations/shared';
import { QRIS } from 'utilities';

function InstructionSection({ viewModel }) {
    const isQRIS = () => {
        return viewModel.data.payment_info && viewModel.data.payment_info.payment_provider === QRIS;
    };
    return (
        <>
            {isQRIS() ? (
                <>
                    {viewModel.instructions?.data?.map((instruction) => {
                        return (
                            <div className="">
                            <ScanQRISInstruction
                                instruction={instruction}
                            />
                        </div>
                        )
                    })
                   }
                </>
            ) : (
                <PaymentCodeInstructionSection
                    payment_info={viewModel.data.payment_info}
                    partner={viewModel.partner}
                    instructions={viewModel.instructions}
                />
            )}
        </>
    );
}

export default InstructionSection;
