import React from 'react';
import { ButtonComponent } from 'presentations/shared/components';

function ModalOnGoingTransaction({ onClose, onClick1, onClick2, title_text, text, text_button1, text_button2, img }) {

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed bottom-0 md:inset-0 lg:inset-0 z-50 outline-none focus:outline-none">
                <div className="opacity-75 fixed inset-0 z-51 bg-blue-dark_blue" onClick={onClose}></div>
                <div className="relative w-screen mx-auto max-w-3xl md:px-32 lg:px-32 z-52">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative px-4 flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*body*/}
                        <div className="relative flex-auto w-full pt-10 pb-6">
                            <img alt="" src={img} className="mx-auto" />
                            <p className="font-face-tselbold mx-auto text-center text-topbar my-6">{title_text}</p>
                            <p className="text-topbar font-face-poppinslight text-center">{text}</p>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center py-6 flex-col gap-2">
                            <ButtonComponent
                                onClick={onClick1}
                                disabled={false}
                                text={text_button1}
                                type={'submit'}
                                version={2}
                            />
                            <ButtonComponent
                                onClick={onClick2}
                                disabled={false}
                                text={text_button2}
                                type={'submit'}
                                version={1}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalOnGoingTransaction;
