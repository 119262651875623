import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Header, ProductDetail } from 'presentations/shared';
import { getToken } from 'utilities';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';
import useViewModel from './InboxPaymentCodePageViewModel';
import { ErrorPage } from '..';
import InstructionSection from './InstructionSection';
import Code from './Code';

// inbox payment page for payment code, validated based on jwt token
function InboxPaymentCodePage() {
    const { t } = useTranslation();
    const { data, validatePaymentCode, error, notify, partner, hours, minutes, seconds, instructions } = useViewModel();
    const [token, setToken] = useState('');
    let url = '';
    if (data.redirect_url_back) {
        url = data.redirect_url_back;
    } else {
        url = data.redirect_url_logo;
    }

    useEffect(() => {
        setToken(getToken);
        if (token) {
            validatePaymentCode(token);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    // show error page
    if (error) {
        return <ErrorPage />;
    }

    return (
        <div className="flex flex-grow h-full lg:min-h-screen md:h-full bg-gray-200 w-full lg:px-40 xl:px-98">
            <Header channel={data.channel} />
            <div className="h-full w-full pt-12 lg:w-full">
                {/* Main Section, showing Payment Code or QR Code */}
                <Code data={data} notify={notify} partner={partner} time={{ hours, minutes, seconds }} />
                <div className="w-full">
                    <div className="-mt-2 lg:mt-4 md:mt-0">
                        <ProductDetail items={data.item_details ? data.item_details : {}} />
                    </div>
                    <p className="text-category text-gray-hero_title px-4 pt-6 pb-3 font-face-poppinsbold">
                        {t('payment_instruction')}
                    </p>
                    {/* Instruction section for Payment Code or QR Code */}
                    <InstructionSection viewModel={{ data, instructions, partner}} />
                    <div className="bg-white h-auto w-full flex flex-wrap content-center py-5 px-4 mt-2">
                        <button
                            className="border-red-tsel border rounded-3xl w-full h-auto bg-red-tsel p-4 hover:bg-red-500"
                            onClick={() => {
                                window.location.replace(url);
                            }}
                        >
                            <p className="text-white text-button font-face-poppinsbold">{t('back_to_shopping')}</p>
                        </button>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default InboxPaymentCodePage;
