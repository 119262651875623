import React, { useState } from 'react';
import { t } from 'i18next';
import IconSecure from 'presentations/assets/images/icons/icon_secure.svg';

function SecurePayment() {
    return (
        <div class="w-full flex place-content-center mt-2 text-sm">
            <img src={IconSecure} alt="Payment" className="mr-2" />
            <span>
                {t('secure_payment_by')} <span class="font-face-tselbold">Telkomsel</span>
            </span>
        </div>
    );
}

export default SecurePayment;
