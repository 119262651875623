import React from 'react';

export default function CashtagForm({ onInput, value, placeholder, account, onSubmit, disabled }) {
    const handleSubmit = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            onSubmit();
        }
    };

    return (
        <div className="mt-2">
            <p className="font-face-poppinsregular text-sm mt-2">{account} cashtag *</p>
            <div class="relative">
                <form className="" onKeyDown={handleSubmit}>
                    <label>
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                            <p className="w-24 h-5 font-face-poppinsregular text-sm mt-2 m-2">$</p>
                        </div>
                        <div class="relative mt-2 rounded-md shadow-sm">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                {!disabled && <span class="text-gray-500 sm:text-sm">$</span>}
                            </div>
                            <input
                                type="text"
                                name="price"
                                id="price"
                                class="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm/6"
                                placeholder={placeholder}
                                disabled={disabled}
                                value={value}
                                onInput={onInput}
                                onKeyDown={(e) => {
                                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </div>

                    </label>
                </form>
            </div>
        </div>
    );
}
