import React from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import CloseIcon from '@mui/icons-material/Close';
import { getFirstLine } from 'utilities/stringSplit';

function ModalDescription({ onClose, description }) {
    const sanitizer = DOMPurify.sanitize;
    const { t } = useTranslation();
    return (
        <>
            <div className="justify-center items-center flex overflow-hidden left-0 fixed bottom-0 md:inset-0 lg:inset-0 z-50 outline-none focus:outline-none">
                <div className="opacity-75 fixed inset-0 z-51 bg-blue-dark_blue" onClick={onClose} data-testid="overlay"></div>
                <div className="relative w-full mx-auto lg:max-w-none z-52">
                    {/*content*/}
                    <div className="border-0 md:w-3/4 lg:w-2/4 md:mx-auto lg:mx-auto rounded-t-xl md:rounded-b-xl lg:rounded-b-xl shadow-lg relative flex flex-col w-screen bg-white outline-none focus:outline-none px-4 pb-6 pt-3">
                        <button
                            className="flex absolute right-6 top-3 text-gray-pull hover:text-gray-700 w-3 h-3"
                            onClick={onClose}
                        >
                            <CloseIcon color="inherit" />
                        </button>
                        {/*body*/}
                        <div className="relative flex-auto w-full lg:w-auto mt-6">
                            <div className='Description-modal' dangerouslySetInnerHTML={{ __html: sanitizer(description) }} />
                            {/*footer*/}
                            <div className="flex items-center flex-col mt-3">
                            <button
                                className="bg-red-tsel w-full text-white active:bg-emerald-600 font-bold text-button p-4 rounded-button shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                                type="button"
                                onClick={onClose}
                            >
                                {t('understand')}
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalDescription;
