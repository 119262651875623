import React from 'react';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';
import Failed from '../../../assets/images/failed.png';

export default function PartnerInstructionSegment({ instruction }) {
    const { t } = useTranslation();
    return (
        <>
            {instruction || true ? (
                <div className="p-2">
                    <ul className="list-disc list-inside space-y-2 text-gray-700">
                        <li>{t('jenius_instruction1')}</li>
                        <li>{t('jenius_instruction2')} </li>
                    </ul>
                </div>
            ) : (
                <>
                    <div className="h-36 w-36 lg:h-60 lg:w-60 mx-auto mt-4 lg:mt-4">
                        <img src={Failed} alt="Failed" />
                    </div>
                    <p className="mt-4 text-sm lg:text-xl font-face-poppinsbold text-center">{t('failed_tnc')}</p>
                   
                </>
            )}
        </>
    );
}
