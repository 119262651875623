import React from 'react';
import 'utilities/i18n';
import ErrorPage from '../error-page';
import { BRI_DD, cc_format } from 'utilities';
import { useTranslation } from 'react-i18next';
import useViewModel from './DirectDebitPageViewModel';
import OtpPage from 'presentations/pages/directdebit-page/OtpPage';
import { PartnerHeader, TopBar, ModalAlert } from 'presentations/shared';
// import Bri from '../../../presentations/assets/images/bri.png';
// import Telkomsel from '../../../presentations/assets/images/Gradient.png';

export default function DirectDebitPage() {
    const { t } = useTranslation();
    const {
        error,
        buttonCollor,
        goToPreviousPath,
        showModalAlert,
        setShowModalAlert,
        showError,
        initiateBinding,
        payment_info,
        setDataDirectDebit,
        dataDirectDebit,
        agree,
        handleClick,
        handleOtp,
        showOtp,
        setShowOtp,
        modalWording,
        stateGlobal,
        buttonText,
        buttonTextCollor,
        regex,
        otpWarning,
        handletoLP,
    } = useViewModel();

    if (error) {
        if (stateGlobal.upp_data.failed_url !== '') {
            window.location.replace(stateGlobal.upp_data.failed_url);
        } else {
            return <ErrorPage />;
        }
    }

    return (
        <>
            <div className="flex flex-col flex-grow h-screen bg-gray-200 lg:px-40">
                <TopBar text={t('addnewcard')} onClick={goToPreviousPath} />
                <div className="h-screen flex flex-col w-full justify-between">
                    <div className="bg-gray-200">
                        <div className="px-8 lg:px-20 py-4 lg:pb-6 rounded-2xl border-2 border-white m-4 bg-white bg-opacity-50 ">
                            <PartnerHeader
                                channel={stateGlobal.upp_data.channel}
                                partnerlogo={payment_info.payment_provider}
                            />
                            <p className="mt-3 font-face-poppinsregular text-sm">
                                {payment_info.payment_provider === BRI_DD ? t('confirmationstatement') : ''}
                            </p>
                            <p className="mt-3 font-face-poppinsregular text-sm">
                                {payment_info.payment_provider === BRI_DD ? t('confirmationstatementone') : ''}
                            </p>
                            <p className="mt-3 font-face-poppinsregular text-sm">
                                {payment_info.payment_provider === BRI_DD ? t('confirmationstatementtwo') : ''}
                            </p>
                            <p className="mt-3 font-face-poppinsregular text-sm">
                                {payment_info.payment_provider === BRI_DD ? t('confirmationstatementthree') : ''}
                            </p>
                            <p className="mt-3 font-face-poppinsregular text-sm">
                                {payment_info.payment_provider === BRI_DD ? t('confirmationstatementnote') : ''}
                            </p>
                        </div>
                        <div className="rounded-2xl border-2 border-white m-4 bg-white bg-opacity-50 h-22 w-full py-5 px-4 lg:px-20">
                            <button
                                type="submit"
                                className={`rounded-3xl w-full mx-auto flex ${buttonCollor} py-4 lg:py-5 px-5`}
                                onClick={handleClick}
                                disabled={showError}
                            >
                                <p className={` ${buttonTextCollor} text-button font-face-poppinsbold mx-auto`}>
                                    {buttonText}
                                </p>
                            </button>
                        </div>
                        {payment_info.payment_provider === BRI_DD && agree === true ? (
                            <div className="px-8 lg:px-20 py-4 bg-white mt-2 lg:mt-4 mb-4">
                                <form className="lg:mt-4 mt-2">
                                    <div>
                                        <label className="font-face-poppinsbold text-xs lg:text-sm">
                                            {t('debit_number')}
                                        </label>
                                        <br />
                                        <input
                                            type="text"
                                            placeholder={t('debit_number_placeholder')}
                                            maxLength="4"
                                            className="w-full border-b-2 border-gray-textinputborder focus:outline-yes text-xs lg:text-sm bg-white-textinput px-2 py-2 mt-2"
                                            onChange={(e) =>
                                                setDataDirectDebit({
                                                    ...dataDirectDebit,
                                                    card_number: cc_format(e.target.value.replace(/[^0-9]/g, '')),
                                                })
                                            }
                                            value={dataDirectDebit.card_number}
                                        />
                                        {dataDirectDebit.card_number.length > 0 &&
                                        dataDirectDebit.card_number.length < 4 ? (
                                            <p className="text-red-error text-xs lg:text-sm mt-2">
                                                {t('invalid_cc_number')}
                                            </p>
                                        ) : null}
                                    </div>
                                    <label className="font-face-poppinsbold text-xs lg:text-sm mt-6">
                                        {t('credit_validity')}
                                    </label>
                                    <br />
                                    <div className="flex flex-row items-center">
                                        <input
                                            type="text"
                                            className="w-full border-b-2 border-gray-textinputborder focus:outline-yes text-xs lg:text-sm bg-white-textinput px-2 py-2 mt-2 lg:w-1/3"
                                            placeholder="mm"
                                            maxLength="2"
                                            onChange={(e) =>
                                                setDataDirectDebit({
                                                    ...dataDirectDebit,
                                                    card_validity_month: e.target.value.replace(/[^0-9]/g, ''),
                                                })
                                            }
                                            value={dataDirectDebit.card_validity_month}
                                        />
                                        <p className="text-blue-dark_blue text-base mx-2">/</p>
                                        <input
                                            type="text"
                                            className="w-full border-b-2 border-gray-textinputborder focus:outline-yes text-xs lg:text-sm bg-white-textinput px-2 py-2 mt-2 lg:w-2/3"
                                            placeholder="yy"
                                            maxLength="2"
                                            onChange={(e) =>
                                                setDataDirectDebit({
                                                    ...dataDirectDebit,
                                                    card_validity_year: e.target.value.replace(/[^0-9]/g, ''),
                                                })
                                            }
                                            value={dataDirectDebit.card_validity_year}
                                        />
                                    </div>
                                    {regex.test(dataDirectDebit.card_validity_month) === false &&
                                    dataDirectDebit.card_validity_month.length > 0 ? (
                                        <p className="text-red-error text-xs lg:text-sm mt-2">{t('invalid_month')}</p>
                                    ) : null}
                                    <div>
                                        <label className="font-face-poppinsbold text-xs lg:text-sm">
                                            {t('phone_number')}
                                        </label>
                                        <br />
                                        <input
                                            type="text"
                                            disabled
                                            value={'62'}
                                            style={{ width: '10%' }}
                                            className="w-full border-b-2 border-gray-textinputborder focus:outline-yes text-xs lg:text-sm bg-white-textinput px-2 py-2 mt-2 lg:w-1/3"
                                        />
                                        <input
                                            type="text"
                                            style={{ width: '90%' }}
                                            placeholder={t('phone_number_placeholder')}
                                            maxLength="13"
                                            className="w-full border-b-2 border-gray-textinputborder focus:outline-yes text-xs lg:text-sm bg-white-textinput px-2 py-2 mt-2 lg:w-1/3"
                                            onChange={(e) =>
                                                setDataDirectDebit({
                                                    ...dataDirectDebit,
                                                    phone_number: Number(e.target.value.replace(/[^0-9]/g, '')),
                                                })
                                            }
                                            value={dataDirectDebit.phone_number}
                                        />
                                        {dataDirectDebit.phone_number.length > 0 &&
                                        dataDirectDebit.phone_number.length < 10 ? (
                                            <p className="text-red-error text-xs lg:text-sm mt-2">
                                                {t('invalid_phone_number')}
                                            </p>
                                        ) : null}
                                    </div>
                                </form>
                                <div className="h-22 w-full py-5 px-4 lg:px-20">
                                    <button
                                        type="submit"
                                        className="rounded-3xl w-full mx-auto flex bg-red-tsel py-4 lg:py-5 px-5 hover:bg-red-500 disabled:opacity-50 disabled:bg-red-tsel"
                                        onClick={initiateBinding}
                                        disabled={
                                            (dataDirectDebit.card_number.length < 4) |
                                            (dataDirectDebit.card_validity_month.length < 2) |
                                            (dataDirectDebit.card_validity_year.length < 2) |
                                            (dataDirectDebit.phone_number.length < 10) |
                                            (regex.test(dataDirectDebit.card_validity_month) === false)
                                                ? true
                                                : showError
                                        }
                                    >
                                        <p className="text-white text-button font-face-poppinsbold mx-auto">
                                            {t('add_card')}
                                        </p>
                                    </button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            {showOtp && (
                <OtpPage
                    text_button="verifikasi"
                    onClick={() => setShowOtp(false)}
                    handleOtp={handleOtp}
                    otpWarning={otpWarning}
                    type="binding"
                />
            )}

            {showModalAlert && (
                <ModalAlert
                    title_text={t(modalWording.modalTitle)}
                    text={t(modalWording.modalText)}
                    text_button={t(modalWording.modalButton)}
                    onClick={() => handletoLP()}
                    img={modalWording.modalImg}
                />
            )}
        </>
    );
}
