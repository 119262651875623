import React, { useState } from 'react';
import { AGGREGATOR_PARTNER, price } from 'utilities';
import Balance from '../../../assets/images/balance.svg';
import PaymentCategory from './PaymentCategory';
import PaymentPartner from './PaymentPartner';
import { useTranslation } from 'react-i18next';
import PaymentPartnerCollapse from './PaymentPartnerCollapse';

const RecommendationSection = (props) => {
    const {
        onChanged,
        recomendationData,
        selectedValue,
        token,
        body,
        msisdnType,
        balance,
        onDirectOpenBindingPage,
        onBind,
        onUnbind,
        checkBinding,
    } = props;

    const { t } = useTranslation();
    const [expand, setExpand] = useState(false);
    // set selected partner
    const controlProps = (item) => ({
        checked: selectedValue === item,
        onChange: onChanged,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
    });

    const RenderPaymentPartner = () => {
        const renderPayment = [];
        recomendationData.map((item, index, array) => {
            if (item.partner_provider === 'balance') {
                renderPayment.push(
                    <PaymentPartner
                        balance_nominal={`${price(balance)}`}
                        logo={Balance}
                        desc={''}
                        items={{}}
                        body={body}
                        title={'balance'}
                        onChanged={controlProps('balance')}
                        balance={msisdnType}
                        token={token}
                    />
                )
            } else {
                renderPayment.push(
                    <PaymentPartner
                        last={array.length - 1 === index}
                        key={index}
                        items={item}
                        isChecked={item.partner_provider === selectedValue.split('|')[0]}
                        onBind={onBind}
                        onUnbind={onUnbind}
                        onCheckBinding={checkBinding}
                        token={token}
                        body={body}
                        onDirectBinding={onDirectOpenBindingPage}
                        onChanged={controlProps(
                            item.partner_provider + '|' + (item.bank_name ? item.bank_name : '')
                        )}
                    />
                );
            }
            return null;
        })
        return renderPayment;
    }

    if (recomendationData && recomendationData.length !== 0) {
        return (
            <div className="flex flex-col px-3.5 mt-4 mb-4 rounded-2xl">
                {recomendationData.length !== 0 ? (
                    <PaymentCategory title={t('recommendation')} expand={expand} setExpand={setExpand} />
                ) : null}
                {expand ? (
                    <RenderPaymentPartner/>
                ) : (
                    <PaymentPartnerCollapse expand={expand} setExpand={setExpand} data={recomendationData} />
                )}
            </div>
        );
    }
    return null;
};

export default RecommendationSection;
