import React, { useState } from 'react';
// import { AGGREGATOR_PARTNER } from 'utilities';
import Balance from '../../../assets/images/balance.svg';
import PaymentCategory from './PaymentCategory';
import PaymentPartner from './PaymentPartner';
import PaymentPartnerCollapse from './PaymentPartnerCollapse';
import { price } from '../../../../utilities';
import { useTranslation } from 'react-i18next';

const Section = (props) => {
    const {
        onChanged,
        data,
        selectedValue,
        onBind,
        body,
        onUnbind,
        checkBinding,
        categoryCode,
        token,
        balance,
        msisdnType,
        last,
    } = props;

    const { t } = useTranslation();
    const [isExpand, setIsExpand] = useState(false);

    const controlProps = (item) => ({
        checked: selectedValue === item,
        onChange: onChanged,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
    });

    const RenderPaymentPartner = () => {
        const renderPayment = [];
        data.filter((word) => word.category.includes(categoryCode)
            && ((word.method !== null)))
        .map((itemType, index, array) => {
            if (itemType.partner_provider === 'balance') {
                renderPayment.push(
                    <PaymentPartner
                        balance_nominal={`${price(balance)}`}
                        logo={Balance}
                        desc={''}
                        items={{}}
                        body={body}
                        title={'balance'}
                        onChanged={controlProps('balance')}
                        balance={msisdnType}
                        token={token}
                    />
                )
            } else {
                renderPayment.push(
                    <PaymentPartner
                        last={array.length - 1 === index}
                        key={index}
                        items={itemType}
                        token={token}
                        isChecked={itemType.partner_provider === selectedValue.split('|')[0]}
                        onBind={onBind}
                        body={body}
                        onUnbind={onUnbind}
                        onCheckBinding={checkBinding}
                        onChanged={controlProps(
                            itemType.partner_provider + '|' + (itemType.bank_name ? itemType.bank_name : '')
                        )}
                    />
                );
            }
            return null;
        })
        return renderPayment;
    }

    if (data.filter((word) => word.category.includes(categoryCode)).length !== 0) {
        return (
            <div className={`flex flex-col px-3.5 rounded-2xl ${!last && `mb-4`}`}>
                {data.filter(
                    (word) =>
                        word.category === categoryCode &&
                        ((word.method !== null))
                ).length !== 0 ? (
                    <PaymentCategory title={t(categoryCode)} expand={isExpand} setExpand={setIsExpand} />
                ) : null}
                {isExpand ? (
                   <RenderPaymentPartner />
                ) : (
                    <PaymentPartnerCollapse
                        expand={isExpand}
                        setExpand={setIsExpand}
                        data={data.filter((word) => word.category.includes(categoryCode) && word.method !== null) || []}
                    />
                )}
            </div>
        );
    }
    return null;
};

export default Section;
