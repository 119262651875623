export const getFirstLine = (input) => {
  
  if (!input) return "";
  
  const endIndex = input.indexOf("</p>");
  
  if (endIndex !== -1) {
    return input.slice(0, endIndex) + "</p>";
  }
  
  const firstNewline = input.indexOf("\n");
  return firstNewline !== -1 ? input.slice(0, firstNewline) : input;
};