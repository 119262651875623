import { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import 'utilities/i18n';
import { getStaticConnetingPage, checkPaymentStatusElisa } from 'domain/use-case';
import { setLoadingState } from 'adapters';
import {
    checkDevice,
} from 'utilities';
import DOMPurify from 'dompurify';

export default function DirectPageViewModel() {
    const { state } = useLocation();
    const stateGlobal = useSelector((states) => states.upp_data);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    let { partner } = useParams();
    const { body, token, total} = state;
    const [number, setNumber] = useState('');
    const [error, setError] = useState(false);
    const [redirectUrl, setredirectUrl] = useState(false);
    const [showModalAlert, setShowModalAlert] = useState(false);
    const [showLoadingAlert, setShowLoadingAlert] = useState(false);
    const [showModalOnGoingTransaction, setShowModalOnGoingTransaction] = useState(false);
    const [deviceType, setDeviceType] = useState('');
    const [loadingTnC, setLoadingTnC] = useState(false);
    const sanitizer = DOMPurify.sanitize;
    const [paymentId, setPaymentID] = useState('');
    const [minutes, setMinutes] = useState(10);
    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [instruction, setInstruction] = useState(null);


    const countHourMinutesSeconds = useCallback(
        (interval) => {
            if (minutes === 0) {
                clearInterval(interval);
                setIsActive(false);
            } else {
                setMinutes(minutes - 1);
                setSeconds(59);
            }
        },
        [minutes]
    );

    const countDown = useCallback(
        (interval) => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else if (seconds === 0) {
                countHourMinutesSeconds(interval);
            }
        },
        [countHourMinutesSeconds, seconds]
    );

    useEffect(() => {
        let myInterval;
        if (isActive) {
            // Timer hanya berjalan jika isActive = true
            myInterval = setInterval(() => {
                countDown(myInterval);
                if (minutes == 0 && seconds == 0) {
                    triggerCheckPaymentStatusElisa();
                }
            }, 1000);
        }
        return () => {
            clearInterval(myInterval);
        };
    }, [countDown, isActive, minutes, seconds]);

    const startTimer = () => {
        setIsActive(true);
    };

    const goToPreviousPath = () => {
        window.location.replace(body?.redirect_url_back);
    };


    useEffect(() => {
        setDeviceType(checkDevice());
        dispatch(setLoadingState({ loading: true }));
        startTimer()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        token && getStaticConnectingPageView()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const getStaticConnectingPageView = async () => {
        setLoadingTnC(true);
        let reqBody = {
            payment_provider: body.payment_info.payment_provider,
            bank_name: body.payment_info.bank_name,
            platform: stateGlobal.device ? stateGlobal.device.toLowerCase() : checkDevice().toLocaleLowerCase(),
        };
        try {
            const response = await getStaticConnetingPage(token, reqBody);
            if (response && response.status_code === '00') {
                setInstruction(response);
                dispatch(setLoadingState({ loading: false }));
            } else {
                dispatch(setLoadingState({ loading: false }));
            }
            setLoadingTnC(false);
        } catch (error) {
            setLoadingTnC(false);
            dispatch(setLoadingState({ loading: false }));
        }
    };

    const triggerCheckPaymentStatusElisa = async () => {
        dispatch(setLoadingState({ loading: true }));
        try {
            const response = await checkPaymentStatusElisa(token);
            console.log('response jen 5');

            if (response) {
                dispatch(setLoadingState({ loading: true }));
                if (response.status_code === '00') {
                    window.location.replace(response.redirect_url);
                } else if (response.status_code === '12') {
                    setShowLoadingAlert(true);
                    setredirectUrl(response.redirect_url);

                    // window.location.replace(response.redirect_url);
                } else {
                    window.location.replace(body.redirect_url);
                }
            }
        } catch (e) {
            setError(true);
            dispatch(setLoadingState({ loading: false }));
        }
    };

    const handleLoading = () => {
        setShowLoadingAlert(false);
        window.location.replace(body?.redirect_url_back);
    };

    return {
        body,
        error,
        goToPreviousPath,
        partner,
        number,
        setNumber,
        showModalAlert,
        setShowModalAlert,
        sanitizer,
        loadingTnC,
        minutes,
        seconds,
        showModalOnGoingTransaction,
        setShowModalOnGoingTransaction,
        triggerCheckPaymentStatusElisa,
        showLoadingAlert,
        handleLoading
    };
}
