export const sortingPaymentPartner = (listPaymentPartner, category) => {
  const listPartner = [...listPaymentPartner]
  const sortByCategory = listPartner.sort((a, b) => {
    if (category.indexOf(a['category']) > category.indexOf(b['category'])) {
      return 1;
    } else if (category.indexOf(a['category']) < category.indexOf(b['category'])) {
      return -1;
    } else {
      return 0;
    }
  })

  const recommendation = sortByCategory.filter((item) => item.is_recommendation_payment === true);
  const listSort = listPaymentPartner.filter((item) => (item.is_recommendation_payment === false));

  return { recommendation, listSort }
}