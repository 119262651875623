import { useState, useEffect, useCallback } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {
    addHours,
    DEFAULT_HOUR,
    DEFAULT_INHOUR_INDOMARET,
    diff_hours,
    FINNET_VA,
    INDOMARET,
    MCP_VA,
    VIRTUAL_ACCOUNT,
    copyToClipboard,
    XENDIT_INVOICES,
} from 'utilities';
import 'utilities/i18n';
import { getValidateInbox } from 'domain/use-case';
import { useDispatch } from 'react-redux';
import { setLoadingState } from 'adapters';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import getStaticInstructionPayment from 'domain/use-case/payment/getStaticInstructionPayment';

export default function InboxPaymentCodePageViewModel() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [hours, setHours] = useState();
    const [error, setError] = useState(false);
    const [partner, setPartner] = useState('');
    const [data, setData] = useState({});
    const [durationInHour, setDurationInHour] = useState(0);
    const [instructions, setInstructions] = useState();

    // setting countdown based on created_time params from response
    const setCountDown = useCallback((remainingHour) => {
        let hour = Math.floor(remainingHour);
        let minute = Math.floor((remainingHour - hour) * 60);
        let second = 0;
        setHours(hour);
        setMinutes(minute);
        setSeconds(second);
    }, []);

    useEffect(() => {
        // set countdown change every second
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    setMinutes(59);
                    if (hours === 0) {
                        clearInterval(myInterval);
                    } else {
                        setHours(hours - 1);
                    }
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [minutes, seconds, hours]);

    // calculate remaining expiry time
    const calculateHours = useCallback(
        (createdDate) => {
            setCountDown(diff_hours(new Date(addHours(durationInHour, new Date(createdDate))), new Date()));
        },
        [durationInHour, setCountDown]
    );

    // format remaining time to hour unit
    const getHour = useCallback((res) => {
        switch (res.payment_info.duration_unit) {
            case 'second':
                setDurationInHour(res.payment_info.expiry_duration / 3600);
                break;
            case 'minute':
                setDurationInHour(res.payment_info.expiry_duration / 60);
                break;
            case 'hour':
                setDurationInHour(res.payment_info.expiry_duration);
                break;
            case 'day':
                setDurationInHour(res.payment_info.expiry_duration * 24);
                break;
            default:
                if (res.payment_info.payment_provider === INDOMARET) {
                    setDurationInHour(DEFAULT_INHOUR_INDOMARET);
                } else {
                    setDurationInHour(DEFAULT_HOUR);
                }
        }
    }, []);

    // set partner name display
    const getPartner = useCallback((items) => {
        if (
            items.payment_info.payment_provider === VIRTUAL_ACCOUNT ||
            items.payment_info.payment_provider === FINNET_VA ||
            items.payment_info.payment_provider === MCP_VA ||
            items.payment_info.payment_provider === XENDIT_INVOICES
        ) {
            setPartner(items.payment_info.bank_display_name.toUpperCase());
        } else {
            setPartner(
                items.payment_info.payment_provider.charAt(0).toUpperCase() +
                    items.payment_info.payment_provider.slice(1)
            );
        }
    }, []);

    // calling validate jwt token API
    const validatePaymentCode = useCallback(
        async (auth) => {
            dispatch(setLoadingState({ loading: true }));
            try {
                const response = await getValidateInbox(auth);
                if (response) {
                    getPartner(response);
                    getHour(response);
                    calculateHours(response.created_time);
                    setData(response);
                    getInstructions(auth, response.payment_info.payment_provider, response.payment_info.bank_name);
                    dispatch(setLoadingState({ loading: false }));
                } else {
                    setError(true);
                    dispatch(setLoadingState({ loading: false }));
                }
            } catch (e) {
                setError(true);
                dispatch(setLoadingState({ loading: false }));
            }
        },
        [calculateHours, getPartner, getHour, dispatch]
    );

    // copy payment code and show toast
    const notify = () => {
        toast.info(t('copied_to_clipboard'), {
            position: 'top-left',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        copyToClipboard(data.payment_info.payment_code);
    };

    const getInstructions = async (token, payment, bank_name = null) => {
        const payload = {
            partner_code: payment,
            partner_agg_code: bank_name,
        }
        try {
            const response = await getStaticInstructionPayment(payload,token);
            if (response && response.status_code === '00') {
                setInstructions(response);
            }
        } catch (e) {
            console.error(e);
        }
    }

    return {
        data,
        partner,
        hours,
        minutes,
        seconds,
        validatePaymentCode,
        error,
        notify,
        instructions,
    };
}
