import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonPartner = () => {
  return (
    [1,2,3,4].map((item, index) => {
      return (
        <div key={item} className='flex flex-col px-3.5 rounded-2xl py-2'>
          <div
                className="flex w-full justify-between bg-gray-category pl-2 pr-4 py-2 rounded-t-2xl border border-white"
            >
                <Skeleton variant="text" width="100%" height={50} />
            </div>
            <div className="flex w-full bg-white pl-2 pr-4 py-2 rounded-b-2xl flex-wrap min-h-10">
              <Skeleton variant="text" width="100%" height={50}/>
            </div>
        </div>
      );
    })
  );
};

export default SkeletonPartner;