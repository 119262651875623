import React from 'react';
import {
    TopBar,
    ModalAlert,
    LinkAjaWco,
    ModalOnGoingTransaction,
} from 'presentations/shared';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import 'utilities/i18n';
import ErrorPage from '../error-page';
import useViewModel from './DirectPageViewModel';
import { DirectPageCard } from './Components';
import WarningLogo from 'presentations/assets/images/warning.png';

// static connecting page contain Terms and Condition and phone number form for OVO
export default function DirectPage() {
    const { t } = useTranslation();
    const stateGlobal = useSelector((state) => state.upp_data);
    const {
        body,
        error,
        goToPreviousPath,
        partner,
        number,
        setNumber,
        showModalAlert,
        setShowModalAlert,
        instruction,
        sanitizer,
        loadingTnC,
        minutes,
        seconds,
        showModalOnGoingTransaction,
        setShowModalOnGoingTransaction,
        triggerCheckPaymentStatusElisa,
        handleLoading,
        showLoadingAlert,
    } = useViewModel();

    // show error page or redirect to failed url
    if (error) {
        if (stateGlobal.failed_url && stateGlobal.failed_url !== '') {
            window.location.replace(stateGlobal.failed_url);
        } else {
            return <ErrorPage />;
        }
    }

    return (
        <>
            <div className="flex flex-col flex-grow bg-gray-200 lg:px-40 xl:px-98 min-h-screen">
                <TopBar
                    partner={partner}
                    text={t('confirm')}
                    onClick={() => setShowModalOnGoingTransaction(true)}
                />
                <DirectPageCard
                    body={body}
                    partner={partner}
                    number={number}
                    setNumber={setNumber}
                    instruction={instruction}
                    sanitizer={sanitizer}
                    loadingTnC={loadingTnC}
                    minutes={minutes}
                    seconds={seconds}
                    triggerCheckPaymentStatusElisa={triggerCheckPaymentStatusElisa}
                />
            </div>
            {showModalAlert && (
                <ModalAlert
                    text={t('jeniusalert')}
                    text_button="OK"
                    onClick={() => setShowModalAlert(false)}
                />
            )}
            {showLoadingAlert && (
                <ModalAlert
                    title_text={t('payment_on_proses')}
                    text={t('notif')}
                    text_button={t('back_to_home')}
                    img={WarningLogo}
                    onClick={handleLoading}
                />
            )}

            {showModalOnGoingTransaction && (
                <ModalOnGoingTransaction
                    onClose={() => setShowModalOnGoingTransaction(false)}
                    onClick1={goToPreviousPath}
                    onClick2={() => setShowModalOnGoingTransaction(false)}
                    title_text={t('transaction_ongoing')}
                    text={t('tansaction_ongoing_detail')}
                    text_button1={t('understand')}
                    text_button2={t('cancel')}
                    img={WarningLogo}
                />
            )}
        </>
    );
}
