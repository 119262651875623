import React from 'react';
import { PartnerHeader, CashtagForm } from 'presentations/shared';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';
import { JENIUS } from 'utilities';
import LoadingComponent from 'presentations/shared/LoadingComponent';
import PartnerInstructionSegment from './PartnerInstructionSegment';
import { ButtonComponent } from 'presentations/shared/components';

export default function DirectPageCard({
    body,
    partner,
    instruction,
    sanitizer,
    loadingTnC,
    minutes,
    seconds,
    triggerCheckPaymentStatusElisa
}) {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col w-auto justify-between rounded-2xl border-2 border-white m-4 bg-white bg-opacity-50 gap-2 px-8 py-4 lg:px-20 lg:pb-6	">
            {loadingTnC ? <LoadingComponent /> : null}
            <PartnerHeader
                channel={instruction?.connector_data?.url_icon_channel}
                partnerlogo={instruction?.connector_data?.url_icon_partner}
                isTransparant={true}
            />
            {partner === JENIUS || body?.payment_info?.bank_name === JENIUS ? (
                <CashtagForm
                onInput={() => {
                }}
                    value={body?.payment_info?.payment_id}
                    placeholder={t('cashtag_placeholder')}
                    account={partner}
                    onSubmit={()=>{}}
                    disabled={true}
                />
            ) : null}
            <PartnerInstructionSegment
                instruction={instruction}
                sanitizer={sanitizer}
            />

            <div className="h-22 w-full py-5 lg:px-20">
            <ButtonComponent
                        onClick={triggerCheckPaymentStatusElisa}
                        disabled={false}
                        text={`${t('already_paid')}     ${minutes} : ${seconds} `}
                        type={'submit'}
                        version={2}
                    />
            </div>
        </div>
    );
}
